@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
  background: rgb(40, 40, 40);
}

/* All users */
.all-users {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.single-user {
  background: rgb(0, 213, 255);
  color: white;
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 10px;
  margin-right: 0.5rem;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.user-online {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgb(0, 219, 0);
  position: absolute;
  top: 1px;
  right: -3px;
  z-index: 2;
}


/* User Card */
.user-card {
  color: white;
  position: relative;
  border-bottom: 1px solid rgb(100, 100, 100);
  width: 100%;
  padding: 3%;
  display: flex;
  flex-direction: column;
}

.user-card .user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.user-card .name {
  font-weight: bold;
  color: #fff;
}

.user-card .text-content {
  color: #d5d3d3;
  margin-top: 0.5rem;
}

.user-card .notifications {
  display: flex;
  align-items: center;
}

.user-card .notifications .this-user-notifications {
  background-color: rgb(83, 164, 231);
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.user-card .user-online {
  background-color: rgb(77, 234, 77);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-left: 1rem;
}

.user-card .date-row {
  margin-top: 0.5rem;
}

.user-card .date-content {
  font-size: 0.875rem;
  color: #838080;
  text-align: right; /* Align the date to the right */
}

/* Responsive adjustments */
@media (min-width: 640px) {
  .user-card {
    flex-direction: column;
    width: 80%;
  }

  .user-card .user-info {
    flex-grow: 1;
    width: 80%;
  }

  .user-card .notifications {
    justify-content: flex-end;
  }
}


.text-content {
  gap: 0.75rem;
color: #6b7280;
}


.name {
  font-weight: 600;
  color: #374151;
}

.message-text {
  font-size: 0.875rem;
  color: #6b7280;
}

.date-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.date {
  font-size: 0.875rem;
  color: #6b7280;
}
.notification-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #14b8a6;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: white;
  border-radius: 9999px;
}

.status-indicator {
  display: inline-block;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 9999px;
  background-color: #10b981;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  z-index: 20;
}

/* .user-online {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgb(0, 219, 0);
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 2;
  margin-top: 20px;
} */

/* Chat Box */
.chat-box {
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
  background: rgb(25, 25, 25);
  border-radius: 10px;
  width: 100%;
}

.messages-box {
  height: 85vh;
}

/* Chat Header */
.chat-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  color: white;
  background: rgb(30, 30, 30);
}

/* Messages */
.message {
  color: white;
  background: #282828;
  padding: 0.75rem;
  border-radius: 5px;
  max-width: 50%;
  align-self: flex-start; /* Default alignment to the left */
}

/* Styling for messages sent by the user */
.message.self {
  background: #00bd9b;
  align-self: flex-end; /* Aligns the message to the right */
  border-radius: 10px 10px 0 10px; /* Rounded corners for sent messages */
  max-width: 50%; /* Consistent max-width */
  margin-right: 10px; /* Margin on the right side */
}
.align-self-start {
  align-items: flex-start;
}

/* Ensure the message container uses flexbox */
.messages {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Space between messages */
  margin-top: 2%;
}
.message-footer {
  font-size: 12px;
  align-self: flex-end;
  font-weight: 400;
}

/* Chat Input  */
.chat-input {
  display: flex;
  align-items: center; /* Align items on the same line vertically */
}

.InputEmoji {
  flex-grow: 1; /* Make the input field take up the remaining space */
}

.send-btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Notification */
.notifications {
  position: relative;
}

.notifications-icon {
  color: white;
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
}

.notifications-box {
  max-height: 50vh;
  width: 300px;
  position: absolute;
  top: 2rem;
  right: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: #181d31;
  color: white;
  z-index: 5;
}

.notifications-header {
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.notifications-box h3 {
  font-weight: 700;
  font-size: 20px;
}

.mark-as-read {
  cursor: pointer;
  font-weight: 700;
  opacity: 0.8;
}

.notification {
  font-size: 14px;
  margin: 1rem 0;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid rgb(207, 207, 207);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.notification.not-read {
  background: #263159;
}

.notification-time {
  margin-top: 0.2rem;
  font-size: 12px;
  color: #e0e0e0;
}

.notification-count {
  display: flex;
  background: #00bd9b;
  height: 25px;
  width: 25px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -15px;
}

.this-user-notifications {
  
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #14b8a6;
    height: 1.5rem;
    width: 1.5rem;
    font-size: 0.75rem;
    font-weight: 700;
    color: white;
    border-radius: 9999px;
}



 .chat-container {
  border-bottom: 1px solid #d1d5db;
  padding: 0.75rem 1rem;
  width: 100%;
  transition: background-color 0.2s;
}

.chat-container:hover {
  background-color: #f9fafb;
}


.open-messages-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff; /* Adjust color as needed */
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}

.open-messages-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
.coin-info {
  margin-top: 10px;
  padding: 10px;
  background-color: #2c2f36;
  border-radius: 5px;
  color: #fff;
}

.coin-info h4 {
  margin-bottom: 10px;
  color: #e0b521;
}

.coin-info p {
  margin: 5px 0;
}


/* Add this to your component's CSS file or global styles */
.admin-account-details h2 {
  color: #FFCC00; /* Yellow color */
  font-size: 1.5rem;
  font-weight: bold;
}

.admin-account-details h3 {
  font-size: 1.2rem;
  color: #333;
}

.admin-account-details ul {
  list-style-type: none;
  padding-left: 0;
}

.admin-account-details li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f9fa; /* Light background */
  border: 1px solid #ddd;
  border-radius: 5px;
}

.admin-account-details li strong {
  font-weight: bold;
  color: #0056b3; /* Dark blue */
}
